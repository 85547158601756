<template>
  <button class="rating-button" v-bind:style="styles">
    <span v-html="label"></span>
  </button>
</template>

<script>
export default {
  name: 'RatingButton',
  props: {
    label: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
    },
    selected: {
      type: Boolean,
      required: false,
    },
  },
  data: function () {
    return {
      hover: false,
    }
  },
  computed: {
    styles() {
      let styles = {}
      if (this.selected || this.hover) {
        styles['color'] = this.color
        styles.transition = 'all 250ms linear'
      }
      return styles
    },
  },
}
</script>

<style scoped lang="scss">
.rating-button {
  padding: 5px 11px;
  margin: 4px 2px;
  font-size: 13px;
  color: #838383;
  background-color: white;
  outline: none;
  font-weight: 500;
}

.rating-button:hover {
  color: v-bind(color);
}
.rating-button:focus-visible {
  outline-color: v-bind(color);
  outline-style: double;
}
</style>
