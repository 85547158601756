<template>
  <div class="form-screen">
    <screen-header :externalLink="url"></screen-header>
    <div class="form" v-if="form">
      <embedded-content
        :url="embeddedURL"
        i-frame-title="Ovation CXM Form"
      ></embedded-content>
    </div>
  </div>
</template>

<script>
import ScreenHeader from '@/components/ScreenHeader'
import EmbeddedContent from '@/components/EmbeddedContent'

export default {
  name: 'FormScreen',
  props: {
    form: {
      type: Object,
      required: false,
    },
  },
  inject: ['partnerStore', 'issueStore', 'memberStore'],
  data: function () {
    return {
      logObjectId:
        this.form.logObject === 'members_locations'
          ? this.memberStore.member.member_locations_id
          : this.issueStore.issue.id,
    }
  },
  components: { ScreenHeader, EmbeddedContent },
  methods: {},
  mounted() {},
  computed: {
    url() {
      let url
      if (this.form.publicURL) {
        url = this.form.publicURL
      } else {
        url =
          this.form.url +
          '&integrationId=' +
          this.partnerStore.config.integrationId

        if (this.logObjectId) url = url + '&logObjectId=' + this.logObjectId
      }
      return url
    },
    embeddedURL() {
      let url
      if (this.form.publicURL) {
        url = this.form.publicURL + '?viewmode=embedded'
      } else {
        url =
          this.form &&
          this.form.url +
            '&viewmode=embedded&integrationId=' +
            this.partnerStore.config.integrationId
        if (this.logObjectId) url = url + '&logObjectId=' + this.logObjectId
      }
      return url
    },
  },
}
</script>

<style scoped>
.forms-screen-title {
  font-size: 16px;
  font-weight: 500;
}

.form-li {
  list-style: none;
  border-bottom: 1px solid #e0e0e0;
  padding: 8px 0px;
}

.form-li:hover {
  font-weight: 500;
  cursor: pointer;
}
</style>
