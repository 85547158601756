import { reactive, watch } from 'vue'

const state = reactive({
  query: '',
  lastQuery: '',
  results: null,
})

function addResults(results) {
  if (!state.results) state.results = []
  state.results.push(results)
}

function replaceResults(results) {
  state.results = results
}

function clearResults() {
  state.results = null
}

watch(
  () => state.query,
  (newValue, oldValue) => {
    if (oldValue && !newValue) {
      state.query = ''
      state.lastQuery = ''
      state.results = null
    }
  },
)

export const kbStore = {
  state,
  addResults,
  clearResults,
  replaceResults,
}
