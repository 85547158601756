<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    :width="width"
    :height="height"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      :stroke="stroke"
      stroke-width="10"
      r="35"
      stroke-dasharray="164.93361431346415 56.97787143782138"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      ></animateTransform>
    </circle>
  </svg>
</template>

<script>
export default {
  name: 'CircleLoader',
  props: {
    color: {
      type: String,
      required: false,
    },
    size: {
      type: Number,
      required: false,
    },
  },
  computed: {
    stroke() {
      return this.color || '#ddd'
    },
    height() {
      if (this.size) return this.size + 'px'
      return '20px'
    },
    width() {
      if (this.size) return this.size + 'px'
      return '20px'
    },
  },
}
</script>

<style scoped lang="scss"></style>
