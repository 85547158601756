<template>
  <button
    class="action-button"
    v-bind:class="{ selected }"
    :disabled="disabled"
  >
    <span v-html="label"></span>
  </button>
</template>

<script>
export default {
  name: 'ActionButton',
  props: {
    label: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
    },
    selected: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  data: function () {
    return {
      hover: false,
      focus: false,
    }
  },
}
</script>

<style scoped lang="scss">
.action-button {
  padding: 5px 11px;
  margin: 4px 2px;
  font-size: 13px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  color: #838383;
  background-color: white;
  outline: none;
  font-weight: 500;
  text-align: left;
}
.action-button:hover:enabled {
  border-color: v-bind(color);
  transition: all 250ms ease;
}
.action-button:focus-visible {
  outline-color: v-bind(color);
  outline-style: double;
  transition: all 250ms ease;
}

.selected {
  background-color: v-bind(color);
  border-color: v-bind(color);
  color: #fff;
  transition: all 250ms ease;
}

.action-button:disabled {
  cursor: not-allowed;
}
</style>
