import axios from 'axios'
import { partnerStore } from '../stores/partnerStore'
// import {kbStore} from '../stores/kbStore';
import globals from '../globals'

async function checkUser(email) {
  let url = globals.apiHost + '/members/user_check?email=' + email
  let response = await axios.get(url)
  return response.data.exists
}

async function createMember(email, firstName, phone) {
  let url = globals.apiHost + '/members/create'
  let data = {
    members: {
      email: email,
      adhoc: true,
      partners_teams_ids: partnerStore.config.partnerTeam,
    },
    members_users: {
      first_name: firstName,
      email: email,
    },
    members_locations: {
      phone: phone,
    },
  }
  let response = await axios.post(url, data)
  return response.data.results
}

async function getMember(email, firstName, phone) {
  let url = globals.apiHost + '/members/lookupByData2'
  let data = {
    authPartnerTeamId: partnerStore.config.partnerTeam,
    memberCity: null,
    memberCountry: null,
    memberName: null,
    memberState: null,
    memberStreet: null,
    memberUserEmail: email,
    memberUserFirstName: firstName,
    memberUserPhone: phone,
    memberZipcode: null,
  }

  let response = await axios.post(url, data)
  return response.data.results
}

async function getEmoticons() {
  let url = globals.apiHost + '/members/meta/emoticons'
  try {
    let response = await axios.get(url)
    return response.data.emoticons
  } catch (e) {
    console.log('e! ', e)
    return []
  }
}

export { checkUser, createMember, getMember, getEmoticons }
