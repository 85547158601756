import { checkUser, createMember, getMember } from '@/api/memberApi'
import { memberStore } from '@/stores/memberStore'

const identifyUser = async user => {
  if (user.memberUsersId) {
    identifyUserById()
  } else if (user.externalId) {
    identifyUserByExternalId()
  } else if (user.email) {
    identifyUserByEmail(user)
  }
}

const identifyUserByEmail = async user => {
  let member
  try {
    let userExists = await checkUser(user.email)
    if (userExists) {
      member = await getMember(user.email, user.firstName, user.phone)
      memberStore.setMember(member)
    }
  } catch (error) {
    // todo: log and notify of error
  }
}

const identifyUserById = async id => {}

const identifyUserByExternalId = async id => {}

export { identifyUser }
