import { reactive, watch } from 'vue'
import { remove } from 'lodash'

const notifications = reactive([])

function addNotification(notification) {
  notification.id =
    notification.id || Math.floor(Math.random() * 999999).toString()
  notifications.push(notification)
}

function removeNotification(id) {
  remove(notifications, notification => {
    return notification.id === id
  })
}

export const notificationStore = {
  notifications,
  addNotification,
  removeNotification,
}
