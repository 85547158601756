<template>
  <div class="journey">
    <screen-header></screen-header>
    <div class="journey-screen">
      <ul class="journey-list">
        <li
          v-for="(journey, index) in journeyStore.state.journeys"
          :key="index"
          class="journey-li"
          @click="goTojourney(journey)"
        >
          <img
            src="@/assets/boomtown-logo.png"
            alt="boomtown-logo"
            class="journey-li-icon"
          />
          <div class="journey-description">
            <span class="bt-icon bt-icon-journey"></span>
            <div class="journey-description-body">
              <div class="journey-name">
                {{ journey.journey_data.name }}
              </div>
              <div class="journey-text">
                {{
                  `You are ${journey.journey_data.percentComplete}% complete! Click here to see what's next`
                }}
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ScreenHeader from '@/components/ScreenHeader'

export default {
  name: 'JourneyListScreen',
  inject: ['journeyStore'],
  data: function () {
    return {}
  },
  components: { ScreenHeader },
  methods: {
    goTojourney(journey) {
      journey.id = journey.journey_id
      this.$router.push({ name: 'JourneyScreen', params: journey })
    },
  },
}
</script>

<style scoped>
.journey-screen {
  padding: 0px 24px;
}

.journey-li {
  list-style: none;
  line-height: 1.5;
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
}

.journey-li:hover {
  font-weight: 500;
  cursor: pointer;
}

.journey-li-icon {
  width: 30px;
  height: 30px;
}

.journey-description {
  border: 1px solid #ebebeb;
  border-radius: 6px;
  padding: 8px;
  width: 90%;
  display: flex;
  justify-content: space-between;
}

.journey-description-body {
  width: 94%;
  margin-left: 10px;
}

.journey-name {
  font-size: 16px;
  font-weight: 500;
  vertical-align: middle;
}

.journey-text {
  font-size: 14px;
  color: #aaaaaa;
}

.bt-icon-journey {
  color: #aaaaaa;
  vertical-align: middle;
}
</style>
