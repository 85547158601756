<template>
  <div class="kb-screen">
    <screen-header :downloadLink="url"></screen-header>
    <img :src="url" alt="" class="image" />
  </div>
</template>

<script>
import ScreenHeader from '@/components/ScreenHeader'

export default {
  name: 'ImagePreview',
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {}
  },
  components: { ScreenHeader },
  computed: {},
  mounted() {
    // TODO: allow caching on these images
  },
}
</script>

<style scoped>
.image {
  margin: auto;
  display: block;
  margin-top: 6px;
  max-width: 100%;
}
</style>
