var merge = require('lodash/merge')
import { reactive, computed, readonly } from 'vue'
import { webColors } from './webColors'

const baseThemeColor = '#0b99ba'
const theme = reactive({
  primaryColor: baseThemeColor,
  primaryContrastColor: '#fff',
  primaryTextColor: '#424851',
  primaryHoverColor: lightenColor(baseThemeColor, 25),
  secondaryColor: getSecondaryColor(baseThemeColor),
  inputPlaceholderColor: '#d6d6d6',
})

function setTheme(themeObject) {
  merge(theme, themeObject)
}

function setThemeColor(color) {
  theme.primaryColor = color
  theme.primaryHoverColor = lightenColor(color, 25)
  theme.secondaryColor = getSecondaryColor(color)
  // todo: check for contrast and set primary contrast color
}

// example of exporting full css-in-js object
const exampleStyles = computed(() => {
  return {
    background: theme.primaryColor,
    borderColor: theme.primaryColor,
    color: '#fff',
  }
})

export const themeStore = readonly({
  theme,
  exampleStyles,
  setTheme,
  setThemeColor,
})

function lightenColor(color, amount) {
  if (!color) return
  if (color[0] !== '#' && color[0] !== 'r') return color
  return (
    '#' +
    color
      .replace(/^#/, '')
      .replace(/../g, color =>
        (
          '0' +
          Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
        ).substr(-2),
      )
  )
}

function getSecondaryColor(color) {
  try {
    if (!color) return
    if (webColors[color.toLowerCase()]) {
      color = webColors[color]
    }
    if (color[0] === '#') {
      color = hexToRgbA(color)
    }
    color = color.replace(',1)', ',.1)')
    return color
  } catch (error) {
    return '#fff'
  }
}

function hexToRgbA(hex) {
  var c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return (
      'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)'
    )
  }
  throw new Error('Bad Hex')
}
