<template>
  <div class="typing-indicator-wrapper" ref="typingIndicator">
    <div class="avatar-wrapper" v-if="participant.avatar">
      <img class="avatar" :src="participant.avatar" />
    </div>
    <div class="from-name">
      {{ participant.name }}
    </div>
    <div class="typing-indicator">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TypingIndiciator',
  provide: {},
  props: {
    participant: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {}
  },
  methods: {},
  mounted() {
    window.setTimeout(() => {
      this.$refs.typingIndicator && this.$refs.typingIndicator.scrollIntoView()
    }, 500)
  },
}
</script>

<style scoped lang="scss">
.typing-indicator-wrapper {
  margin-bottom: 6px;
  height: 40px;
  position: relative;
  padding-left: 36px;
}

.avatar-wrapper {
  overflow: hidden;
  position: absolute;
  top: 24px;
  left: 0px;
  width: 32px;
  height: 32px;
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 14px;
  border: 1px solid #fff;
  background: #fff;
}

.typing-indicator {
  display: table;
  float: left;
  width: auto;
  padding: 10px;
  margin-left: 19px;
  margin-top: 8px;
  background-color: #e6e7ed;
  will-change: transform;
  border-radius: 50px;
  position: relative;
  animation: 2s bulge infinite ease-out;
  &::before,
  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: -11px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: #e6e7ed;
  }
  &::after {
    height: 6px;
    width: 6px;
    left: -18px;
    bottom: -3px;
  }
  span {
    height: 6px;
    width: 6px;
    float: left;
    margin: 0 1px;
    background-color: #9e9ea1;
    display: block;
    border-radius: 50%;
    opacity: 0.4;
    @for $i from 1 through 3 {
      &:nth-of-type(#{$i}) {
        animation: 1s blink infinite ($i * 0.3333s);
      }
    }
  }
}
@keyframes blink {
  50% {
    opacity: 1;
  }
}

@keyframes bulge {
  50% {
    transform: scale(1.05);
  }
}

.from-name {
  font-size: 11px;
  font-weight: bold;
  color: #97afc1;
}
</style>
