<template>
  <div class="form-screen">
    <screen-header :externalLink="url"></screen-header>
    <div class="form" v-if="journey">
      <embedded-content
        :url="url"
        i-frame-title="Ovation CXM Journey"
      ></embedded-content>
    </div>
  </div>
</template>

<script>
import ScreenHeader from '@/components/ScreenHeader'
import EmbeddedContent from '@/components/EmbeddedContent'

export default {
  name: 'JourneyScreen',
  props: {
    journey: {
      type: Object,
      required: false,
    },
  },
  inject: ['partnerStore'],
  data: function () {
    return {}
  },
  components: { ScreenHeader, EmbeddedContent },
  methods: {},
  mounted() {},
  computed: {
    url() {
      // TODO: Change this to global HOST property
      return (
        'https://boomtown.preprod.goboomtown.com/screens/journeys/index.php?issue_id=' +
        this.journey.issue_id +
        '&viewmode="embedded'
      )
    },
  },
}
</script>

<style scoped>
.forms-screen-title {
  font-size: 16px;
  font-weight: 500;
}

.form-li {
  list-style: none;
  border-bottom: 1px solid #ebebeb;
  padding: 8px 0px;
}

.form-li:hover {
  font-weight: 500;
  cursor: pointer;
}
</style>
