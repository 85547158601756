import { createRouter, createWebHashHistory } from 'vue-router'
import HomeScreen from '../views/HomeScreen.vue'
import ChatScreen from '../views/ChatScreen.vue'
import FormListScreen from '../views/FormListScreen.vue'
import FormScreen from '../views/FormScreen.vue'
import JourneyListScreen from '../views/JourneyListScreen.vue'
import JourneyScreen from '../views/JourneyScreen.vue'
import KBListScreen from '../views/KBListScreen.vue'
import KBScreen from '../views/KBScreen.vue'
import ImagePreview from '../views/ImagePreview.vue'
import EndChat from '../views/EndChat.vue'
import { startCase } from 'lodash'
import { partnerStore } from '../stores/partnerStore'

const routes = [
  {
    path: '/',
    name: 'HomeScreen',
    component: HomeScreen,
  },
  {
    path: '/chat',
    name: 'ChatScreen',
    component: ChatScreen,
  },
  {
    path: '/chat/image-preview',
    name: 'ImagePreview',
    component: ImagePreview,
    props: route => {
      console.log('route: ', route)
      return {
        url: route.query.url || route.params.url,
      }
    },
  },
  {
    path: '/chat/end',
    name: 'EndChat',
    component: EndChat,
    props: route => {
      return {
        url: route.params.url,
      }
    },
  },
  {
    path: '/forms',
    name: 'FormListScreen',
    component: FormListScreen,
  },
  {
    path: '/form/:id?',
    name: 'FormScreen',
    component: FormScreen,
    props: route => {
      return {
        form: {
          ...route.params,
          ...route.query,
        },
      }
    },
  },
  {
    path: '/journeys',
    name: 'JourneyListScreen',
    component: JourneyListScreen,
  },
  {
    path: '/journey/:id?',
    name: 'JourneyScreen',
    component: JourneyScreen,
    props: route => {
      return {
        journey: {
          ...route.params,
        },
      }
    },
  },
  {
    path: '/kbs',
    name: 'KBListScreen',
    component: KBListScreen,
  },
  {
    path: '/kb/:id?',
    name: 'KBScreen',
    component: KBScreen,
    props: route => {
      return {
        kb: {
          ...route.params,
          ...route.query,
        },
      }
    },
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.afterEach((to, from) => {
  const pageTitle = 'Messenger ' + startCase(to.name)
  document.title = pageTitle
  if (from.name) {
    window.sessionStorage.setItem(`${partnerStore.config.integrationId}.last-path`, to.fullPath)
  }
})

export default router
