<template>
  <div class="home" v-bind:class="{ 'full-screen': configStore.fullScreen }">
    <div class="brand-stripe"></div>
    <close-button
      v-if="!configStore.config.embedded"
      class="close-button"
    ></close-button>
    <h1 class="home-title">
      {{ partnerStore.config.homeTitle || 'Welcome' }}
    </h1>
    <h2 class="home-subtitle">
      {{ partnerStore.config.homeSubTitle || 'How can we help you?' }}
    </h2>

    <div
      class="home-section"
      id="chat-section"
      v-if="partnerStore.config.unavailable"
    >
      <div class="away-message">
        {{ partnerStore.config.unavailableSummary }}
      </div>
    </div>
    <div
      class="home-section"
      id="chat-section"
      v-if="!partnerStore.config.unavailable"
    >
      <h3 class="home-section-title">
        {{ partnerStore.config.chatTitle || "Need Help? Let's Chat" }}
      </h3>
      <!-- Zero State, No Started Chat Start -->
      <PrimaryCTA
        v-if="!issueStore.issue.id"
        @click="goToChat()"
        text="Start Conversation"
        class="button-link primary-cta"
      ></PrimaryCTA>
      <!-- Chat Started -->
      <SecondaryCTA
        v-if="issueStore.issue.id"
        @click="goToChat()"
        :text="
          issueStore.issue.active ? 'Resume Conversation' : 'View Conversation'
        "
        class="button-link secondary-cta"
        id="home-screen-resume-view-button"
      ></SecondaryCTA>
      <PrimaryCTA
        v-if="issueStore.issue.id && !issueStore.issue.active"
        @click="startNewConversation()"
        text="Start New Conversation"
        class="primary-cta"
      ></PrimaryCTA>
    </div>

    <div
      class="home-section"
      id="kb-section"
      v-if="partnerStore.config.supportKBEnabled"
    >
      <h3 class="home-section-title">
        {{ partnerStore.config.kbTitle }}
      </h3>
      <h4 class="home-section-description">
        {{ partnerStore.config.kbDescription }}
      </h4>
      <k-b-search class="kb-search" :resultLimit="resultLimit"></k-b-search>
    </div>

    <div
      class="home-section"
      id="journey-section"
      v-if="partnerStore.config.supportJourneysEnabled"
    >
      <h3 class="home-section-title">
        {{ partnerStore.config.journeysTitle }}
      </h3>
      <h4 class="home-section-description">
        {{ partnerStore.config.journeysDescription }}
      </h4>
      <SecondaryCTA class="secondary-cta" @click="goToJourneysList()">
        {{ partnerStore.config.supportJourneysButtonText }}
      </SecondaryCTA>
    </div>

    <div
      class="home-section"
      id="email-section"
      v-if="partnerStore.config.supportEmailEnabled"
    >
      <h3 class="home-section-title">
        {{ partnerStore.config.emailTitle }}
      </h3>
      <h4 class="home-section-description">
        {{ partnerStore.config.emailDescription }}
      </h4>
      <a
        class="home-section-link"
        :href="`mailto:${partnerStore.config.supportEmail}`"
        target="_top"
        id="home-screen-email-button"
      >
        {{ partnerStore.config.supportEmail }}
      </a>
    </div>

    <div
      class="home-section"
      id="phone-section"
      v-if="partnerStore.config.supportPhoneEnabled"
    >
      <h3 class="home-section-title">
        {{ partnerStore.config.phoneTitle }}
      </h3>
      <h4 class="home-section-description">
        {{ partnerStore.config.phoneDescription }}
      </h4>
      <a
        class="home-section-link"
        :href="`tel:${partnerStore.config.supportPhone}`"
        target="_top"
        id="home-screen-phone-button"
      >
        {{ partnerStore.config.supportPhone }}
      </a>
    </div>
    <div
      class="home-section"
      id="forms-section"
      v-if="partnerStore.config.supportFormsEnabled"
    >
      <h3 class="home-section-title">
        {{ partnerStore.config.formsTitle }}
      </h3>
      <h4 class="home-section-description">
        {{ partnerStore.config.formsDescription }}
      </h4>
      <SecondaryCTA
        :text="partnerStore.config.supportFormsButtonText || 'Fill Out a Form'"
        class="secondary-cta"
        id="home-screen-forms-button"
        @click="goToFormList()"
      ></SecondaryCTA>
    </div>

    <div
      class="home-section"
      id="web-section"
      v-if="partnerStore.config.supportWebsiteEnabled"
    >
      <h3 class="home-section-title">
        {{ partnerStore.config.webTitle }}
      </h3>
      <h4 class="home-section-description">
        {{ partnerStore.config.webDescription }}
      </h4>
      <a :href="supportWebsiteLink" target="_blank" class="button-link">
        <SecondaryCTA
          :text="partnerStore.config.supportWebsiteButtonText"
          id="home-screen-website-button"
          class="secondary-cta"
        ></SecondaryCTA>
      </a>
    </div>
  </div>
</template>

<script>
import KBSearch from '@/components/KBSearch'
import chatService from '@/services/chatService'
import CloseButton from '@/components/CloseButton'
import SecondaryCTA from '@/components/SecondaryCTA'
import PrimaryCTA from '../components/PrimaryCTA'

export default {
  name: 'Home',
  inject: [
    'chatStore',
    'partnerStore',
    'issueStore',
    'memberStore',
    'configStore',
    'themeStore',
  ],
  components: {
    KBSearch,
    CloseButton,
    SecondaryCTA,
    PrimaryCTA,
  },
  data: function () {
    return {
      resultLimit: 2,
      chatService,
      primaryColor: this.themeStore.theme.primaryColor,
    }
  },
  methods: {
    goToChat(form) {
      this.$router.push({
        name: 'ChatScreen',
      })
    },
    goToForm(form) {
      this.$router.push({
        name: 'FormScreen',
        params: { id: form.id },
        query: form,
      })
    },
    goToFormList() {
      if (this.partnerStore.config.supportFormsList.length > 1) {
        this.$router.push('/forms')
      } else {
        this.goToForm(this.partnerStore.config.supportFormsList[0])
      }
    },
    goToJourneysList() {
      this.$router.push('/journeys')
    },
    startNewConversation() {
      // clear issue
      chatService.resetChat()
      this.$router.push('/chat')
    },
  },
  computed: {
    supportWebsiteLink() {
      if (this.partnerStore.config.supportWebsite) {
        if (!this.partnerStore.config.supportWebsite.match(/^https?:\/\//)) {
          return 'https://' + this.partnerStore.config.supportWebsite
        } else {
          return this.partnerStore.config.supportWebsite
        }
      } else {
        return ''
      }
    },
  },
  mounted() {
    this.partnerStore.addConfig('foo', 'bar')
  },
}
</script>

<style scoped lang="scss">
.home {
  padding: 0px 30px;
}
.home-title {
  font-size: 20px;
  line-height: 26px;
  font-weight: normal;
  padding-top: 38px;
  margin-top: 0px;
  font-weight: 500;
  color: v-bind(primaryColor);
}

.home-subtitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  margin-top: 6px;
  margin-bottom: 30px;
}

.home-section {
  border-top: 1px solid #ddd;
  padding: 30px 0px 36px 0px;
}

.home-section-title {
  margin: 0px;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
}

.home-section-description {
  font-weight: normal;
  margin: 6px 0px 0px 0px;
}

.primary-cta,
.secondary-cta {
  margin-top: 18px;
}

.home-section-link {
  display: block;
  margin-top: 18px;
  font-size: 20px;
  color: v-bind(primaryColor);
}

.away-message {
  color: #32393f;
  font-size: 18px;
  line-height: 22px;
}

.brand-stripe {
  position: absolute;
  right: 0px;
  top: 0px;
  left: 0px;
  height: 8px;
  background-color: v-bind(primaryColor);
}
.close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  display: none;
  color: #596168;
}

.button-link:hover {
  opacity: 1;
}

@media only screen and (max-width: 419px) {
  .close-button {
    display: block;
  }
}

@media only screen and (min-width: 421px) {
  .close-button {
    display: block;
  }
}

.full-screen {
  .close-button {
    display: block;
  }
}

.kb-search {
  margin: 0px -8px;
}
</style>
