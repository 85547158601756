import CryptoJS from 'crypto-js'
// import * as XMPP from 'stanza';
import XMPP from 'stanza.io'
import { chatStore } from '../stores/chatStore'
import { memberStore } from '../stores/memberStore'
import { issueStore } from '../stores/issueStore'
import { configStore } from '../stores/configStore'
import { getComm } from '../api/commApi'
import globals from '../globals'
import Router from '../router'
import linkifyHtml from "linkify-html";

const audio = new Audio('/message.mp3')
const incrementUnreadMessageCount = JSON.stringify({
  type: 'incrementUnreadMessageCount',
  data: null,
})

let timeOffset = 0
var emojiHeight = 20,
  emojiWidth = 20
var pixelDensity = (function () {
  var pixelRatio = 1 // default

  if ('deviceXDPI' in screen) {
    // IE
    pixelRatio = screen.deviceXDPI / screen.logicalXDPI
  } else if (window.hasOwnProperty('devicePixelRatio')) {
    pixelRatio = window.devicePixelRatio
  }
  return pixelRatio
})()

let chatService = {
  client: null,
  thread: null,
  threadJID: null,
  xmppData: null,
  commData: null,
  mucJoined: false,
  emoticons: [],
  clearTypingTimeout: null,
  presenceInterval: null,
  createClient: function (xmppData, commData) {
    if (this.client) return this.client
    this.xmppData = xmppData
    this.commData = commData

    this.client = XMPP.createClient({
      jid: xmppData.jid + '/' + xmppData.resource,
      password: xmppData.password,
      wsURL:
        'wss://' +
        xmppData.wss_host +
        (xmppData.wss_port && parseInt(xmppData.wss_port) !== 443
          ? ':' + xmppData.wss_port
          : '') +
        '/ws/',
      transports: 'websocket',
      reconnect: true,
      maxRequests: 5,
    })

    this.client.on('session:started', () => {
      // make XEP-0202 iq request for server time to determine local offset
      this.client.getTime(xmppData.relay_jid, function (err, reply) {
        if (
          !err &&
          reply &&
          reply.time &&
          reply.time.utc instanceof
            Date /* && Math.abs(reply.time.utc.getTime() - Date.now()) > 30000*/
        ) {
          timeOffset = reply.time.utc.getTime() - Date.now()
        }
      })
      this.client.sendPresence()
      if (this.presenceInterval) window.clearInterval(this.presenceInterval)
      this.presenceInterval = window.setInterval(() => {this.client.sendPresence()}, 1000*59);

      this.client.on('muc:join', stanza => {
        if (stanza.muc.codes.includes('201')) {
          this.client.configureRoom(stanza.from.bare, {}, (error, data) => {})
        }
        this.client.mucJoined = true
      })

      this.client.joinRoom(this.commData.comm.external_id, xmppData.resource, {
        joinMuc: {
          status: 'Boomtown!',
          history: {
            since: new Date('01 January 1970 12:00 UTC'),
            maxstanzas: 500,
          },
        },
      })
    })

    this.client.on('message', stanzaObj => {
      handleChat(stanzaObj, commData, xmppData)
    })

    this.client.on('groupchat', stanzaObj => {
      handleGroupChat(stanzaObj, commData, xmppData)
    })

    this.client.on('chat', stanzaObj => {})
    // .on('auth:failed', resetAuthentication)
    // .on('muc:declined', resetAuthentication)
    // .on('bosh:terminate', chatTerminated)

    this.client.on('session:end', () => {
      console.log('chat client session ended')
    })

    this.client.on('muc:error', function (error) {
      console.log('=-- client.on muc:error ', error)
    })

    this.client.connect()

    return this.client
  },
  resetChat() {
    this.client = null
    this.thread = null
    this.xmppData = null
    this.commData = null
    chatStore.resetChat()
    issueStore.unsetIssue()
    if (this.presenceInterval) window.clearInterval(this.presenceInterval)
  },
  sendMessage: function (message) {
    this.client.sendMessage({
      to: this.commData.comm.external_id,
      body: message || null,
      type: 'groupchat',
      chatState: 'active',
      thread: this.thread,
    })
  },
  parseUri: function (uri) {
    var scheme = uri.substring(0, uri.indexOf(':')),
      authority = uri.substring(
        scheme.length + 3,
        ~uri.indexOf('/', scheme.length + 3) &&
          (!~uri.indexOf('?', scheme.length + 3) ||
            uri.indexOf('/', scheme.length + 3) <
              uri.indexOf('?', scheme.length + 3))
          ? uri.indexOf('/', scheme.length + 3)
          : ~uri.indexOf('?', scheme.length + 3)
          ? uri.indexOf('?', scheme.length + 3)
          : uri.length,
      ),
      path =
        ~uri.indexOf('/', scheme.length + 3) &&
        (!~uri.indexOf('?', scheme.length + 3) ||
          uri.indexOf('/', scheme.length + 3) <
            uri.indexOf('?', scheme.length + 3))
          ? uri.substring(
              uri.indexOf('/', scheme.length + 3),
              ~uri.indexOf('?', scheme.length + 3)
                ? uri.indexOf('?', scheme.length + 3)
                : uri.length,
            )
          : '',
      query = ~uri.indexOf('?', scheme.length + 3)
        ? uri.substr(uri.indexOf('?', scheme.length + 3) + 1)
        : '',
      queryDict = {}
    // convert query to object
    query.split('&').forEach(function (item) {
      queryDict[item.split('=')[0]] = decodeURIComponent(item.split('=')[1])
    })
    return {
      scheme: scheme,
      authority: authority,
      path: path,
      query: query,
      queryDict: queryDict,
    }
  },
}

function removeClient() {
  chatService.client = null
}

async function handleChat(stanzaObj, commData, xmppData) {
  let participantData = {} // todo: what is this?
  let resource = stanzaObj.from.resource.split(';')[0]
  let participant = chatService.commData.participants_eligible[resource]
  if (!participant) {
    await refreshComm()
    participant = chatService.commData.participants_eligible[resource] || {}
  }

  if (
    (stanzaObj.thread || stanzaObj.chatState) &&
    resource !== getUserResourceKey(xmppData) &&
    participant
  ) {
    if (stanzaObj.chatState !== 'gone') {
      onMessageThread(
        stanzaObj.thread || null,
        stanzaObj.from.resource,
        stanzaObj.from.full,
        false,
      )
    } else if (stanzaObj.chatState === 'gone') {
      onMessageThread(null, stanzaObj.from.resource, stanzaObj.from.full, false)
    }

    // handle chat state notifications
    if (stanzaObj.chatState === 'composing') {
      onComposing(stanzaObj.from.full, participant, false)
    } else if (stanzaObj.chatState === 'paused') {
      onPaused(stanzaObj.from.full)
    } else if (stanzaObj.chatState === 'gone') {
      onGone(stanzaObj.from.full)
    }
  }
}

async function handleGroupChat(stanzaObj, commData, xmppData) {
  console.log('handleGroupChat() with stanzaObj.thread: ', stanzaObj.thread)
  if (stanzaObj.thread) {
    chatService.thread = stanzaObj.thread
  }
  let msgConfig = {
    ratingActions: [],
    actions: [],
  }
  let stanzaBody = decodeURIComponent(
    (stanzaObj.body || '')
      .replace(/%/g, '%25')
      .replace(/\r/g, '\\r')
      .replace(/\n/g, '\\n')
      .replace(/\t/g, '\\t'),
  )
  let parsedBody
  let hideMessage = false
  try {
    parsedBody = JSON.parse(stanzaBody)
    if (typeof parsedBody === 'number') {
      parsedBody = {
        message: stanzaBody,
      }
    }
  } catch (error) {
    parsedBody = {
      message: stanzaBody,
    }
    if (!stanzaBody) {
      hideMessage = true
    } else {
      // replace newline characters with a br tag
      parsedBody.message = parsedBody.message.replace(
        /(?:\\r\\n|\\r|\\n)/g,
        '<br>',
      )
      // Apply emoticons
      for (var e = 0, len = chatService.emoticons.length; e < len; ++e) {
        let icon = chatService.emoticons[e].lbl
        parsedBody.message = parsedBody.message.replace(
          new RegExp('\\(' + icon + '\\)', 'g'),
          '<img class="chatEmoji"' +
            ' height="' +
            emojiHeight +
            '"' +
            ' width="' +
            emojiWidth +
            '"' +
            ' alt="(' +
            icon +
            ')"' +
            ' src="' +
            getEmoticon('fixed_avatars', 'emoji-' + icon, [
              emojiHeight * pixelDensity,
              emojiWidth * pixelDensity,
            ]) +
            '"/>',
        )
        if (
          chatService.emoticons[e].shorthand &&
          !parsedBody.message.match(/href="mailto:/)
        ) {
          parsedBody.message = parsedBody.message.replace(
            chatService.emoticons[e].shorthand,
            '<img class="chatEmoji"' +
              ' height="' +
              emojiHeight +
              '"' +
              ' width="' +
              emojiWidth +
              '"' +
              ' alt="(' +
              icon +
              ')"' +
              ' src="' +
              getEmoticon('fixed_avatars', 'emoji-' + icon, [
                emojiHeight * pixelDensity,
                emojiWidth * pixelDensity,
              ]) +
              '"/>',
          )
        }
      }

      // parse links in markdown format, comes from quick replies DEV-621
      // TODO: should we support all markdown?
      let regex = new RegExp(/\[([^\]]+)\]\(([^)]+)\)/g)
      parsedBody.message = parsedBody.message.replace(
        regex,
        '<a href="$2" target="_blank">$1</a>',
      )
      
      // handle all other links
      // use linkifyHtml to support parsing links properly after parsing markup format
      // consider linkifyString if we want to allow users putting code blocks in chat
      parsedBody.message = linkifyHtml(parsedBody.message, {target: '_blank'})
    }
  }
  if (parsedBody.secret === chatStore.endChatMessageSecret) {
    hideMessage = true
  }

  let resource = stanzaObj.from.resource.split(';')[0]
  let participant = chatService.commData.participants_eligible[resource]
  if (!participant) {
    await refreshComm()
    participant = chatService.commData.participants_eligible[resource] || {}
  }

  // Handle user click of bot actions:
  // print labels as text and mark action as selected in bot message
  if (resource.match(/members_users/) && stanzaBody.match(/bt-bot:/)) {
    if (stanzaBody.match(/key=/) || stanzaBody.match(/topic=/)) {
      let labelMessageText = parsedBody.message.split('lbl=')[1]
      labelMessageText = labelMessageText.replace(/\+/g, ' ')
      // double-decoding is required as these labels can end up encoded 2x
      parsedBody.message = decodeURIComponent(
        decodeURIComponent(labelMessageText),
      )
      chatStore.markActionSelected(parsedBody)
    }
  }

  // Handle user click of bot rating
  // hides message and marks rating button as selected
  if (resource.match(/members_users/) && stanzaBody.match(/bt-data:/)) {
    if (stanzaBody.match(/bot_conversation_rating/)) {
      let labelMessageText = parsedBody.message.split('lbl=')[1]
      console.log('---> labelMessageText: ', labelMessageText)
      let rating = labelMessageText.match('Down') ? 'Down' : 'Up'
      parsedBody.rating = rating
      // mark thumb icon selected, but dont show separate message from user
      chatStore.markRatingActionSelected(parsedBody)
      hideMessage = true
    }
  }

  if (parsedBody.actions) {
    for (var index in parsedBody.actions) {
      var action, label
      if (parsedBody.actions[index]) {
        action = parsedBody.actions[index]
        if (
          typeof action === 'object' &&
          action.key &&
          action.lbl &&
          action.uri
        ) {
          // replace emojis in label
          label = action.lbl
          // special handling for rating button thumb up/thumb down icons
          if (label.match('thumbsup')) {
            label =
              '<span class="bt-icon bt-icon-thumb-up" alt="thumbsup"></span>'
          } else if (label == '(thumbsdown)') {
            label =
              '<span class="bt-icon bt-icon-thumb-down" alt="thumbsdown"></span>'
          }
          if (action.uri.match(/bot_conversation_rating/)) {
            msgConfig.ratingActions.push({
              actUri: action.uri,
              commId: stanzaObj.from.local
                .substr(stanzaObj.from.local.indexOf('.') + 1)
                .toUpperCase(),
              fromResource: resource,
              messageId: stanzaObj.id,
              messageBody: msgConfig.message,
              lbl: label,
            })
          } else {
            msgConfig.actions.push({
              actUri: action.uri,
              commId: stanzaObj.from.local
                .substr(stanzaObj.from.local.indexOf('.') + 1)
                .toUpperCase(),
              fromResource: resource,
              messageId: stanzaObj.id,
              messageBody: msgConfig.message,
              lbl: label,
            })
          }
        }
      }
    }
  }

  if (parsedBody.attachment && parsedBody.attachment.name) {
    msgConfig.nickname =
      typeof msgConfig.participant !== 'undefined'
        ? msgConfig.participant.alias
        : parsedBody.context.from_name

    msgConfig.hasAttachment = true
    msgConfig.hasImage = !!~parsedBody.attachment.type.indexOf('image')
    msgConfig.attachmentName = parsedBody.attachment.name
    msgConfig.attachmentSize = formatFileSize(parsedBody.attachment.size)
    msgConfig.attachmentType = parsedBody.attachment.type
    msgConfig.imagePreview = parsedBody.attachment.preview
    msgConfig.attachmentUrl = parsedBody.attachment.url
  }

  if (parsedBody.kb && parsedBody.kb.id) {
    msgConfig.nickname =
      typeof msgConfig.participant !== 'undefined'
        ? msgConfig.participant.alias
        : parsedBody.context.from_name
    msgConfig.hasKb = true
    msgConfig.hasImage = false
    msgConfig.kbId = parsedBody.kb.id
    msgConfig.kbTitle = parsedBody.kb.title
    msgConfig.kbPreview = decodeURIComponent(
      String(parsedBody.kb.preview).replace(/\+/g, ' '),
    )
    msgConfig.kbPreviewLength = parsedBody.kb.preview_length
    msgConfig.kbOwnerPartnerId = parsedBody.kb.owner_partner_id
    msgConfig.kbPartnerIds = parsedBody.kb.partner_ids
      ? parsedBody.kb.partner_ids.split(/,/)
      : []
    msgConfig.kbPerms = parsedBody.kb.perms
  }
  let message = {
    message: parsedBody.message,
    commId: stanzaObj.from.local,
    stanzaId: stanzaObj.id,
    timestamp: stanzaObj.hasOwnProperty('delay')
      ? stanzaObj.delay.stamp
      : new Date(),
    resource,
    name: participant.name,
    nickName: participant.nickName,
    avatar: getAvatar(participant.object, participant.object_id),
    hasAttachment: msgConfig.hasAttachment,
    hasImage: msgConfig.hasImage,
    attachmentName: msgConfig.attachmentName,
    attachmentSize: msgConfig.attachmentSize,
    attachmentType: msgConfig.attachmentType,
    attachmentPreview: msgConfig.imagePreview,
    attachmentUrl: msgConfig.attachmentUrl,
    isEmail: msgConfig.isEmail,
    hasKb: msgConfig.hasKb,
    kbId: msgConfig.kbId,
    kbTitle: msgConfig.kbTitle,
    kbPreview: msgConfig.kbPreview,
    kbPreviewLength: msgConfig.kbPreviewLength,
    kbOwnerPartnerId: msgConfig.kbOwnerPartnerId,
    kbPartnerIds: msgConfig.kbPartnerIds,
    kbPerms: msgConfig.kbPerms,
    hideMessage,
    actions: msgConfig.actions || null,
    ratingActions: msgConfig.ratingActions || null,
    hasNotificationObj: !!msgConfig.notificationObj,
    stanza: stanzaObj,
  }

  // correct timestamp to actual time (based on server time) within user's timezone
  // not sure if this is needed - tested and didn't have a big effect
  if (!stanzaObj.hasOwnProperty('delay') && timeOffset) {
    message.timestamp = new Date(message.timestamp.getTime() + timeOffset)
  }

  if (!hideMessage) {
    chatStore.addMessage(message)
    audio.play()
    if (
      ((Router.currentRoute._value &&
        Router.currentRoute._value.path !== '/chat') ||
        !configStore.config.appOpen) &&
      !message.resource.match(memberStore.member.members_users_id)
    ) {
      window.parent.postMessage(
        incrementUnreadMessageCount,
        globals.parentOrigin,
      )
      this.chatStore.incrementUnreadMessageCount()
    }
  }
  if (
    stanzaObj.body &&
    (stanzaObj.thread || stanzaObj.chatState) &&
    resource !== getUserResourceKey(xmppData) &&
    participant
  ) {
    if (stanzaObj.chatState !== 'gone') {
      onMessageThread(
        stanzaObj.thread || null,
        stanzaObj.from.resource,
        stanzaObj.from.full,
        true,
      )
    } else if (stanzaObj.chatState === 'gone') {
      onMessageThread(null, stanzaObj.from.resource, stanzaObj.from.full, true)
    }

    // handle chat state notifications
    if (stanzaObj.chatState === 'composing') {
      onComposing(stanzaObj.from.full, participant, true)
    } else if (stanzaObj.chatState === 'paused') {
      onPaused(stanzaObj.from.full)
    } else if (stanzaObj.chatState === 'gone') {
      // no todos for groupchat stanza, only for chat stanza
    }
  }

  if (
    chatStore.state.userTyping &&
    participant.name === chatStore.state.userTyping.name
  ) {
    chatStore.clearUserTyping()
  }
}

function onComposing(fromJID, participantData, inGroupChat) {
  if (fromJID.match(/bots:/)) {
    chatStore.setBotTyping({
      name: participantData.name,
      nickName: participantData.alias,
      avatar: getAvatar(participantData.object, participantData.object_id),
    })
  } else if (fromJID.match(/users:/)) {
    chatStore.setUserTyping({
      name: participantData.name,
      nickName: participantData.alias,
      avatar: getAvatar(participantData.object, participantData.object_id),
    })
    if (chatService.clearTypingTimeout)
      window.clearTimeout(chatService.clearTypingTimeout)
    chatService.clearTypingTimeout = window.setTimeout(() => {
      chatStore.clearUserTyping()
    }, 10000)
  }
}

function onMessageThread(thread, fromResource, fromJID, inGroupChat) {
  if (
    fromResource &&
    fromResource.indexOf('bots') === 0 &&
    ~fromJID.indexOf('/bots:')
  ) {
    chatService.thread = thread
    chatService.threadJID = fromJID
    chatStore.clearBotTyping()
  } else if (!inGroupChat) {
    chatService.thread = null
    chatService.threadJID = null
  }
}

function onGone(fromJID) {
  if (fromJID) {
    chatStore.clearUserTyping()
  }
}

function onPaused(fromJID) {
  if (fromJID === chatService.threadJID) {
    chatStore.clearBotTyping()
  }
}

function getUserResourceKey(xmppData) {
  var userResource = xmppData.resource
  if (~userResource.indexOf(';'))
    userResource = userResource.substr(0, userResource.indexOf(';'))
  return userResource
}

function getAvatar(object, object_id) {
  let host = globals.apiHost
  return host + '/avatar/' + object + '/' + object_id + '/64,64'
}

function getEmoticon(object, object_id, dimensions) {
  //https://api.goboomtown.com/api/v2/avatar/bots/H3F-VP7/64,64
  let host = 'https://api.goboomtown.com'
  dimensions = dimensions || [40, 40]
  let avatarDimensions =
    Math.round(dimensions[0]) + 'x' + Math.round(dimensions[1])
  let url =
    host + '/avatar/' + object + '/' + object_id + '/' + avatarDimensions
  return url
}

function formatFileSize(bytes, precision) {
  bytes = Number(bytes)

  var arBytes = [
      {
        UNIT: 'TB',
        VALUE: Math.pow(1024, 4),
      },
      {
        UNIT: 'GB',
        VALUE: Math.pow(1024, 3),
      },
      {
        UNIT: 'MB',
        VALUE: Math.pow(1024, 2),
      },
      {
        UNIT: 'KB',
        VALUE: 1024,
      },
      {
        UNIT: 'B',
        VALUE: 1,
      },
    ],
    result,
    arItem,
    i

  for (i = 0; i < arBytes.length; i++) {
    arItem = arBytes[i]
    if (bytes >= arItem.VALUE) {
      result = bytes / arItem.VALUE
      precision =
        typeof precision === 'number'
          ? precision
          : arItem.UNIT === 'B'
          ? 0
          : result > 99
          ? 1
          : 2
      return String(result.toFixed(precision)) + ' ' + arItem.UNIT
    }
  }
  return '0 B'
}

function refreshComm() {
  let commId = issueStore.issue.comm_id
  let membersUsersId = memberStore.member.members_users_id
  return getComm(commId, membersUsersId).then(commData => {
    chatService.commData = commData
    return commData
  })
}

export default chatService
