<template>
  <div class="forms">
    <screen-header></screen-header>
    <div class="forms-screen">
      <h1 class="forms-screen-title">Select an Option</h1>
      <ul class="forms-list">
        <li
          v-for="(form, index) in partnerStore.config.supportFormsList"
          :key="index"
          class="form-li"
        >
          <button class="form-button" @click="goToForm(form)">
            <div class="form-li-inner">
              <div class="form-li-name">
                {{ form.name }}
              </div>
              <div class="form-li-arrow">
                <div class="bt-icon bt-icon-arrow-right"></div>
              </div>
            </div>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ScreenHeader from '@/components/ScreenHeader'

export default {
  name: 'FormListScreen',
  inject: ['partnerStore'],
  data: function () {
    return {
      brandColor: this.partnerStore.config.buttonColor,
    }
  },
  components: { ScreenHeader },
  methods: {
    goToForm(form) {
      this.$router.push({
        name: 'FormScreen',
        params: { id: form.id },
        query: form,
      })
    },
  },
}
</script>

<style scoped style="scss">
.forms-screen {
  padding: 0px 15px;
}
.forms-screen-title {
  font-size: 20px;
  font-weight: 600;
  margin-left: 10px;
  margin-top: 29px;
}

.form-button {
  width: 100%;
  background: transparent;
  padding-left: 5px;
  font-size: 18px;
  line-height: 26px;
  text-align: left;
}

.form-li {
  list-style: none;
  padding: 0px 0px;
  border-radius: 6px;
}

.form-button:focus-visible {
  outline-style: double;
  outline-color: v-bind(brandColor);
}

.form-li-inner {
  border-bottom: 1px solid #e0e0e0;
  padding: 22px 0px;
  margin: 0px 6px;
}

.form-li:hover {
  background: #f7f8fa;
  cursor: pointer;
  transition: 250ms all linear;
}
.form-li-name {
  display: inline-block;
  width: 92%;
  vertical-align: middle;
}
.form-li-arrow {
  display: inline-block;
  width: 8%;
  vertical-align: middle;
  text-align: right;
}
</style>
