import axios from 'axios'
import { DateTime } from 'luxon'
import { partnerStore } from '../stores/partnerStore'
import { memberStore } from '../stores/memberStore'
import { configStore } from '../stores/configStore'
// import {kbStore} from '../stores/kbStore';
import globals from '../globals'
import { formatTimestamp, addTZtoDateTimeString } from '../utils/helpers'

async function rateIssue(issueId, ratingValue, feedbackText) {
  let url = globals.apiHost + '/issues/rate/' + issueId
  let data = {
    ratings: {
      rating_value: ratingValue,
      comments: feedbackText,
    }
  }
  try {
    let response = await axios.post(url, data)
    return response.data.kb
  } catch (e) {
    console.log('e! ', e)
    let data = {
      success: true,
      current_server_time: '2021-07-06 14:42:41',
    }
    return data
  }
}

async function createIssue() {
  let url = globals.apiHost + '/issues/create'
  let data = {
    issues: {
      members_id: memberStore.member.members_id,
      members_users_id: memberStore.member.members_users_id,
      members_locations_id: memberStore.member.members_locations_id,
      members_name: memberStore.member.member_user.first_name,
      type: 2,
      user_agent: `Web Connect (${partnerStore.config.partnerTeam})`,
      route_issue_to: partnerStore.config.partnerTeam,
    },
    integration_id: partnerStore.config.integrationId,
    partners_teams_id: partnerStore.config.partnerTeam,
    alt_partners_teams_ids: partnerStore.config.altPartnerTeams,
    wc_source: {
      href: configStore.config.sourceURL,
    },
  }

  let response = await axios.post(url, data)
  return response.data.results[0]
}

async function getIssue(issueId) {
  let url = globals.apiHost + '/issues/get/' + issueId
  try {
    let response = await axios.get(url)
    let issue = response.data.results[0]
    issue.created = addTZtoDateTimeString(issue.created)
    issue.updated = addTZtoDateTimeString(issue.updated)
    return issue
  } catch (e) {
    console.log('e! ', e)
    return e
  }
}

async function resolveIssue(issueId) {
  let url = globals.apiHost + '/issues/resolve/' + issueId
  try {
    let response = await axios.post(url)
    return response.data
  } catch (e) {
    console.log('e! ', e)
    return e
  }
}

export { rateIssue, createIssue, getIssue, resolveIssue }
