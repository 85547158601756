import axios from 'axios'
import { partnerStore } from '../stores/partnerStore'
import { kbStore } from '../stores/kbStore'
import globals from '../globals'

async function searchKB(query) {
  let url =
    globals.apiHost +
    '/kb/search?integration_id=' +
    partnerStore.config.integrationId +
    '&query=' +
    query
  try {
    let response = await axios.get(url)
    kbStore.replaceResults(response.data.results)
    kbStore.state.lastQuery = query
    return response.data
  } catch (e) {
    console.log('e! ', e)
  }
}

export { searchKB }
