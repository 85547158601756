var merge = require('lodash/merge')
import { reactive, readonly } from 'vue'

const config = reactive({})

function setConfig(configObject) {
  merge(config, configObject)
}

function addConfig(key, value) {
  config[key] = value
}

function removeConfig(key) {
  delete config[key]
}

export const partnerStore = readonly({
  config,
  setConfig,
  addConfig,
  removeConfig,
})
