<template>
  <button
    v-bind:class="{ 'primary-cta': true, submitting }"
    :disabled="disabled || submitting"
  >
    <span v-if="!submitting">
      <span v-html="text"></span>
      <span :class="`bt-icon bt-icon-${icon}`"></span>
    </span>

    <transition-group name="fadeIn">
      <span v-html="submittingText" v-if="submitting && submittingText"></span>
      <circle-loader
        class="circle-loader"
        v-if="submitting && !submittingText"
        :size="28"
      ></circle-loader>
    </transition-group>
  </button>
</template>

<script>
import CircleLoader from './CircleLoader'

export default {
  name: 'PrimaryCTA',
  inject: ['themeStore'],
  components: { CircleLoader },
  props: {
    color: {
      type: String,
      required: false,
    },
    submitting: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
    },
    text: {
      type: String,
    },
    icon: {
      type: String,
    },
    submittingText: {
      type: String,
    },
  },
  data() {
    return {
      primaryColor: this.themeStore.theme.primaryColor,
      primaryContrastColor: this.themeStore.theme.primaryContrastColor,
    }
  },
}
</script>

<style scoped lang="scss">
@import '../styles/variables';
.primary-cta {
  box-sizing: border-box;
  background: v-bind(primaryColor);
  color: v-bind(primaryContrastColor);
  border-color: v-bind(primaryColor);
  padding: 13px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  width: 100%;
  transition: all 250ms ease;
  position: relative;
}

.primary-cta:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.primary-cta:focus-visible {
  outline-color: v-bind(primaryColor);
  outline-style: double;
  outline-width: thick;
}

.circle-loader {
  display: inline-block;
  vertical-align: middle;
  height: 27px;
  width: 27px;
  max-width: 70%;
}

.bt-icon {
  font-size: 14px;
  font-weight: 700;
}
</style>
