<template>
  <div class="kb-list">
    <div class="kb-list-header">Recommended Articles</div>
    <button
      v-for="(kb, index) in list"
      :key="index"
      class="kb-list-item-wrapper"
      @click="goToKB(kb.kbId, $event)"
    >
      <div class="kb-list-item">
        <div class="kb-list-item-title">
          {{ kb.kbTitle }}
        </div>
        <div class="bt-icon bt-icon-arrow-right"></div>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: 'KBPreview',
  inject: ['themeStore'],
  data() {
    return {
      brandColor: this.themeStore.theme.primaryColor,
    }
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  methods: {
    goToKB(id, $event) {
      $event.stopImmediatePropagation()
      this.$router.push({
        name: 'KBScreen',
        params: { id },
      })
    },
  },
  computed: {},
}
</script>

<style scoped lang="scss">
.kb-list {
  margin-top: 6px;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  width: 82%;
}

.kb-list-header {
  font-weight: 500;
  background: v-bind(brandColor);
  color: white;
  line-height: 45px;
  height: 45px;
  padding-left: 16px;
}

.kb-list-item-wrapper {
  background: #f7f8fa;
  text-align: left;
  width: 100%;
  &:hover {
    transition: 250ms all linear;
    background-color: #f2f2f2;
  }

  &:last-of-type {
    .kb-list-item {
      border: none;
    }
  }

  &:focus-visible {
    transition: 250ms all linear;
    background-color: #f2f2f2;

    .bt-icon-arrow-right {
      color: v-bind(brandColor);
    }
  }
}
.kb-list-item {
  height: 74px;
  width: 230px;
  border-bottom: 1px solid #e0e0e0;
  padding: 16px 0px 16px 0px;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  margin: auto;

  display: flex;
  align-items: center;
}

.kb-list-item-title {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  padding-right: 14px;
}

.bt-icon-arrow-right {
  font-weight: bold;
  top: calc(50% - 7px);
  position: absolute;
  right: 0px;
  color: #898d94;
}
</style>
