<template>
  <span class="timestamp">
    {{ formattedTimestamp }}
  </span>
</template>

<script>
import { formatTimestamp } from '@/utils/helpers'

export default {
  name: 'Timestamp',
  props: {
    datetime: {
      required: true,
    },
    includePrefix: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      formattedTimestamp: this.formatTimestamp(this.datetime),
      timestampInterval: null,
    }
  },
  methods: {
    formatTimestamp(datetime) {
      let formattedTimestamp = formatTimestamp(datetime)

      if (parseInt(formattedTimestamp[0]) && this.includePrefix) {
        return 'at ' + formattedTimestamp
      } else {
        return formattedTimestamp
      }
    },
  },
  mounted() {
    this.timestampInterval = window.setInterval(() => {
      this.formattedTimestamp = formatTimestamp(this.datetime)
    }, 1000 * 61)
  },
  beforeUmount() {
    window.clearInterval(this.timestampInterval)
  },
}
</script>

<style lang="scss" scoped></style>
