<template>
  <div class="chat-loader">
    <div class="chat-bubble">
      <div class="tip"></div>
      <svg
        class="spinner"
        width="62"
        height="62"
        viewBox="0 0 62 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M29.1764 1.14815C29.1764 0.514044 29.6905 0 30.3246 0H31.6754C32.3095 0 32.8235 0.514044 32.8235 1.14815V7.9695C32.8235 8.6036 32.3095 9.11765 31.6754 9.11765H30.3246C29.6905 9.11765 29.1764 8.6036 29.1764 7.9695V1.14815Z"
          :fill="this.fill"
        />
        <path
          opacity="0.04"
          d="M29.1764 54.0305C29.1764 53.3964 29.6905 52.8824 30.3246 52.8824H31.6754C32.3095 52.8824 32.8235 53.3964 32.8235 54.0305V60.8519C32.8235 61.486 32.3095 62 31.6754 62H30.3246C29.6905 62 29.1764 61.486 29.1764 60.8519V54.0305Z"
          :fill="this.fill"
        />
        <path
          opacity="0.5"
          d="M44.3467 4.23578C44.6637 3.68663 45.3659 3.49848 45.9151 3.81553L47.0848 4.49091C47.634 4.80796 47.8222 5.51016 47.5051 6.05931L44.0944 11.9668C43.7774 12.5159 43.0752 12.7041 42.526 12.387L41.3562 11.7116C40.8071 11.3946 40.6189 10.6924 40.936 10.1432L44.3467 4.23578Z"
          :fill="this.fill"
        />
        <path
          opacity="0.06"
          d="M17.9055 50.0332C18.2226 49.4841 18.9248 49.2959 19.4739 49.613L20.6437 50.2883C21.1929 50.6054 21.381 51.3076 21.064 51.8567L17.6533 57.7642C17.3363 58.3133 16.6341 58.5015 16.0849 58.1844L14.9151 57.5091C14.366 57.192 14.1778 56.4898 14.4949 55.9407L17.9055 50.0332Z"
          :fill="this.fill"
        />
        <path
          opacity="0.3"
          d="M17.6533 4.23578C17.3362 3.68663 16.634 3.49848 16.0849 3.81553L14.9151 4.49091C14.3659 4.80796 14.1778 5.51016 14.4948 6.05931L17.9055 11.9668C18.2225 12.5159 18.9247 12.7041 19.4739 12.387L20.6437 11.7116C21.1928 11.3946 21.381 10.6924 21.0639 10.1432L17.6533 4.23578Z"
          :fill="this.fill"
        />
        <path
          d="M44.0945 50.0332C43.7775 49.4841 43.0753 49.2959 42.5261 49.613L41.3563 50.2883C40.8072 50.6054 40.619 51.3076 40.9361 51.8567L44.3468 57.7642C44.6638 58.3133 45.366 58.5015 45.9152 58.1844L47.0849 57.5091C47.6341 57.192 47.8222 56.4898 47.5052 55.9407L44.0945 50.0332Z"
          :fill="this.fill"
        />
        <path
          opacity="0.7"
          d="M60.8519 29.1765C61.486 29.1765 62 29.6905 62 30.3246L62 31.6754C62 32.3095 61.486 32.8235 60.8519 32.8235L54.0305 32.8235C53.3964 32.8235 52.8824 32.3095 52.8824 31.6754L52.8824 30.3246C52.8824 29.6905 53.3964 29.1765 54.0305 29.1765L60.8519 29.1765Z"
          :fill="this.fill"
        />
        <path
          opacity="0.1"
          d="M7.96948 29.1765C8.60359 29.1765 9.11763 29.6905 9.11763 30.3246L9.11763 31.6754C9.11763 32.3095 8.60359 32.8235 7.96948 32.8235L1.14813 32.8235C0.514026 32.8235 -1.73031e-05 32.3095 -1.72754e-05 31.6754L-1.72163e-05 30.3246C-1.71886e-05 29.6905 0.514026 29.1765 1.14813 29.1765L7.96948 29.1765Z"
          :fill="this.fill"
        />
        <path
          opacity="0.8"
          d="M57.7642 44.3466C58.3133 44.6637 58.5015 45.3659 58.1844 45.915L57.509 47.0848C57.192 47.634 56.4898 47.8221 55.9406 47.5051L50.0332 44.0944C49.484 43.7774 49.2959 43.0752 49.6129 42.526L50.2883 41.3562C50.6054 40.8071 51.3076 40.6189 51.8567 40.936L57.7642 44.3466Z"
          :fill="this.fill"
        />
        <path
          opacity="0.2"
          d="M11.9668 17.9055C12.5159 18.2226 12.7041 18.9248 12.387 19.4739L11.7116 20.6437C11.3946 21.1929 10.6924 21.381 10.1432 21.064L4.23578 17.6533C3.68663 17.3363 3.49848 16.6341 3.81553 16.0849L4.49091 14.9151C4.80796 14.366 5.51016 14.1778 6.05931 14.4949L11.9668 17.9055Z"
          :fill="this.fill"
        />
        <path
          opacity="0.6"
          d="M57.7642 17.6533C58.3133 17.3362 58.5015 16.634 58.1844 16.0849L57.509 14.9151C57.192 14.366 56.4898 14.1778 55.9406 14.4949L50.0332 17.9055C49.484 18.2226 49.2959 18.9248 49.6129 19.4739L50.2883 20.6437C50.6054 21.1929 51.3076 21.381 51.8567 21.064L57.7642 17.6533Z"
          :fill="this.fill"
        />
        <path
          opacity="0.08"
          d="M11.9668 44.0945C12.5159 43.7774 12.7041 43.0752 12.387 42.5261L11.7116 41.3563C11.3946 40.8071 10.6924 40.619 10.1432 40.936L4.23578 44.3467C3.68663 44.6637 3.49848 45.3659 3.81553 45.9151L4.49091 47.0849C4.80796 47.634 5.51016 47.8222 6.05931 47.5051L11.9668 44.0945Z"
          :fill="this.fill"
        />
      </svg>
    </div>
    <div class="title">Starting your chat...</div>
    <div class="subtitle">It will be ready in just a few seconds.</div>
  </div>
</template>

<script>
export default {
  name: 'ChatLoader',
  inject: ['themeStore'],
  data: () => {
    return {}
  },
  computed: {
    fill() {
      return this.themeStore.theme.primaryColor || '#0079EB'
    },
  },
}
</script>

<style scoped lang="scss">
.chat-bubble {
  margin: 25% auto 48px auto;
  border-radius: 12px;
  border: 2px solid #e0e0e0;
  height: 110px;
  width: 146px;
  background: white;
  padding-top: 23px;
  position: relative;
}

.tip {
  position: absolute;
  height: 20px;
  width: 20px;
  transform: rotate(45deg);
  border-right: 2px solid #e0e0e0;
  border-bottom: 2px solid #e0e0e0;
  background: white;
  bottom: -12px;
  left: calc(50% - 10px);
}

.spinner {
  animation: spin 2.6s linear infinite;
}

.title {
  font-weight: 600;
  font-size: 20px;
  margin-top: 60px;
}

.subtitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-top: 16px;
}
</style>
