<template>
  <div class="kb-list">
    <screen-header></screen-header>
    <div class="kb-list-screen">
      <k-b-search></k-b-search>
    </div>
  </div>
</template>

<script>
import ScreenHeader from '@/components/ScreenHeader'
import KBSearch from '@/components/KBSearch'

export default {
  name: 'KBListScreen',
  inject: ['partnerStore', 'kbStore'],
  components: { ScreenHeader, KBSearch },
  data: function () {
    return {}
  },
}
</script>

<style scoped lang="scss">
.kb-list-screen {
  padding: 0px 15px;
}
</style>
