<template>
  <button id="close-app-button" class="close-app-button" @click="closeApp()">
    <span class="bt-icon bt-icon-close"></span>
  </button>
</template>

<script>
import { partnerStore } from '@/stores/partnerStore'

export default {
  name: 'CloseButton',
  provide: {
    partnerStore,
  },
  data: function () {
    return {}
  },
  methods: {
    closeApp() {
      window.parent.postMessage(
        JSON.stringify({ type: 'closeApp' }),
        partnerStore.config.parentOrigin,
      )
    },
  },
}
</script>

<style scoped lang="scss">
.close-app-button {
  height: 36px;
  width: 36px;
  line-height: 1;
  font-size: 20px;
  opacity: 0.4;
  border-radius: 6px;
  z-index: 1;
  background: none;
  border: none;
  cursor: pointer;
}

.close-app-button:hover {
  opacity: 0.7;
  transition: all 250ms ease-in-out;
}
</style>
