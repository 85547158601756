<template>
  <button
    class="kb-card"
    @click="goToKB(id, $event)"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <div class="kb-title">{{ title }}</div>
    <!-- <div class="kb-preview"><span v-html="previewContent"></span></div> -->
    <div class="kb-link">Read more</div>
  </button>
</template>

<script>
export default {
  name: 'KBPreview',
  inject: ['themeStore'],
  data() {
    return {
      hover: false,
      primaryColor: this.themeStore.theme.primaryColor,
    }
  },
  props: {
    id: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    preview: {
      type: String,
      required: true,
    },
  },
  methods: {
    goToKB(id, $event) {
      $event.stopImmediatePropagation()
      this.$router.push({
        name: 'KBScreen',
        params: { id },
      })
    },
  },
  computed: {
    // api sends an html version of preview
    // scrape the content for first 2 lines of preview text
    previewContent() {
      let previewContent = ''
      try {
        let div = document.createElement('div')
        div.innerHTML = this.preview
        previewContent = div.textContent.substring(0, 85)
      } catch (error) {
        previewContent = ''
        // todo: log error
      }
      return previewContent
    },
  },
}
</script>

<style scoped lang="scss">
.kb-card {
  color: #626363;
  padding: 10px 18px 6px 10px;
  width: 82%;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  position: relative;
  cursor: pointer;
  transition: 250ms ease-in border-color;
  text-align: left;
  background: transparent;
}

.kb-card:focus-visible {
  outline-color: v-bind(primaryColor);
  outline-style: double;
}

.kb-card:hover {
  border-color: v-bind(primaryColor);
  border-width: 1px;
  border-style: solid;
}

.kb-title {
  width: 100%;
  padding: 0px;
  color: #4f4f4f;
  font-size: 14px;
  font-weight: 500;
}
.kb-preview {
  font-size: 13px;
  color: #596168;
  margin-top: 6px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 2.5em;
  overflow: hidden;
}

.kb-link {
  font-size: 13px;
  font-weight: 600;
  margin-top: 6px;
  color: v-bind(primaryColor);
}
</style>
