<template>
  <div class="list">
    <transition-group name="fadeIn">
      <div
        v-for="(notification, index) in notificationStore.notifications"
        :key="index"
        :class="`wrapper ${notification.level}`"
        @click="notificationStore.removeNotification(notification.id)"
      >
        <button class="close-button">&times;</button>
        <div class="title">{{ notification.title }}</div>
        <div class="message">{{ notification.message }}</div>
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: 'GlobalNotifications',
  inject: ['notificationStore'],
  data: function () {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.list {
  position: absolute;
  top: 8px;
  right: 0px;
  left: 0px;
  z-index: 1;
}

.wrapper {
  position: relative;
  margin-top: 8px;
  border: 1px solid gray;
  border-radius: 6px;
  padding: 12px;
  background: white;
  cursor: pointer;

  &.warning {
    background: #fff3cd;
    border-color: #ffeeba;
    color: #856404;
  }

  &.error {
    background: #f8d7da;
    border-color: #f5c6cb;
    color: red;
  }

  &.success {
    background: #d4edda;
    border-color: #c3e6cb;
    color: #155724;
  }

  &.info {
    background: #d1ecf1;
    border-color: #bee5eb;
    color: #0c5460;
  }
}

.title {
  font-weight: bold;
}

.close-button {
  position: absolute;
  right: 6px;
  top: 6px;
  background: none;
  font-size: 24px;
  color: inherit;
}
</style>
