import { reactive } from 'vue'

const state = reactive({
  journeys: null,
})

function addJourneys(journeys) {
  if (!state.journeys) state.journeys = []
  state.journeys.push(journeys)
}

function replaceJourneys(journeys) {
  state.journeys = journeys
}

function clearJourneys() {
  state.journeys = null
}

export const journeyStore = {
  state,
  addJourneys,
  clearJourneys,
  replaceJourneys,
}
