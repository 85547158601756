var PasteFileHandler = function (el) {
  this._el = el
  this._listenForPaste()
}

PasteFileHandler.prototype._pasteImageSource = function (src) {
  var self = this,
    image = new Image()

  image.onload = function () {
    self._pasteImage(image)
  }

  image.src = src
}

PasteFileHandler.prototype._onPaste = function (e) {
  if (e.clipboardData && e.clipboardData.items) {
    var items = e.clipboardData.items
    let file

    for (var i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        var blob = items[i].getAsFile()
        file = blob
        // images copied from desktop have filenames,
        // only 1 items is present, so emit that file and
        if (items.length === 1) {
          this.emit('paste-file', blob)
        }
        e.preventDefault()
      }
      if (items[i].type.indexOf('text') !== -1) {
        // images copied from the web have 2 items - file, and a string w/ the filename
        // wait till string data is returned, then process and emit file
        items[i].getAsString(s => {
          if (file) {
            // parse filename out of string in <img> tag format
            var el = document.createElement('div')
            el.innerHTML = s
            var img = el.getElementsByTagName('img')[0]
            var fileNameParts = img.src.split('/')
            var fileName = fileNameParts[fileNameParts.length - 1]
            // create new file w/ correct name (names are read-only)
            let newFile = new File([file], fileName, { type: file.type })
            this.emit('paste-file', newFile)
          }
        })
      }
      // todo: can add handling for pasting of files as well as images here
    }
  }
}

PasteFileHandler.prototype._listenForPaste = function () {
  var self = this

  self._origOnPaste = self._el.onpaste

  self._el.addEventListener('paste', function (e) {
    self._onPaste(e)

    // Preserve an existing onpaste event handler
    if (self._origOnPaste) {
      self._origOnPaste.apply(this, arguments)
    }
  })
}

PasteFileHandler.prototype.on = function (event, callback) {
  this._callback = callback
}
PasteFileHandler.prototype.emit = function (event, arg) {
  this._callback(arg)
}

export { PasteFileHandler }
