import axios from 'axios'
import { partnerStore } from '../stores/partnerStore'
import { journeyStore } from '../stores/journeyStore'
import globals from '../globals'

async function getJourneys() {
  let url = ''
  try {
    let response = {}
    response.data = {
      success: true,
      totalCount: 1,
      returned: 1,
      pages: 1,
      results: [
        {
          issue_id: '394b8dd9-b2a7-4f6a-b1ae-a7239aa9df8d',
          customer_id: 'JXD67A',
          customer_location_id: 'JXD67A-UMU',
          journey_id: '718b5890-a034-4152-b5ec-2aceaaaadb35',
          journey_data: {
            name: 'Registration Journey',
            percentComplete: '100',
            first_stage: {
              started: '2021-06-16 12:39:48',
              updated: '2021-06-16 12:39:48',
            },
          },
        },
        {
          issue_id: '394b8dd9-b2a7-4f6a-b1ae-a7239aa9df8d',
          customer_id: 'JXD67A',
          customer_location_id: 'JXD67A-UMU',
          journey_id: '718b5890-a034-4152-b5ec-2aceaaaadb35',
          journey_data: {
            name: 'Activation Journey',
            percentComplete: '50',
            first_stage: {
              started: '2021-06-16 12:39:48',
              updated: '2021-06-16 12:39:48',
            },
            second_stage: {
              started: '2021-06-16 12:39:48',
              updated: '2021-06-16 12:39:48',
            },
          },
        },
      ],
      current_server_time: '2021-06-29 20:42:22',
    }
    // let response = await axios.get(url);
    // return response.data.results
    return {}
  } catch (e) {
    console.log('e! ', e)
  }
}

export { getJourneys }
