import axios from 'axios'
import { partnerStore } from '../stores/partnerStore'
import globals from '../globals'

async function getConfig() {
  try {
    let response = await axios.get(
      globals.apiHost +
        '/providers/get_webconnect?id=' +
        partnerStore.config.integrationId,
    )
    // response.data.supportJourneysEnabled = true
    // response.data.supportJourneysButtonText = 'view workflows'
    // response.data.journeysTitle = 'Great journeys!'
    // response.data.journeysDescription = 'check out our work flows'
    return response.data
  } catch (e) {
    console.log('e! ', e)
  }
}

async function lookupTeam() {
  try {
    let response = await axios.post(
      globals.apiHost +
        '/providers/lookupTeam/' +
        partnerStore.config.partnerTeam,
    )
    return response.data
  } catch (e) {
    console.log('e! ', e)
  }
}

export { getConfig, lookupTeam }
