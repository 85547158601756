<template>
  <div class="embedded-content-loader" v-if="loading"></div>
  <transition name="fadeIn">
    <iframe
      width="100%"
      height="85%"
      scrolling="yes"
      :src="url"
      frameborder="0"
      :onload="handleIframeLoaded"
      class="embedded-content"
      v-show="!loading"
      :title="iFrameTitle"
    ></iframe>
  </transition>
</template>

<script>
export default {
  name: 'EmbeddedContent',
  props: {
    url: {
      type: String,
      required: true,
    },
    iFrameTitle: {
      type: String,
      required: false,
      default: 'Ovation CXM Embedded Content',
    },
  },
  data: function () {
    return {
      loading: true,
    }
  },
  methods: {
    handleIframeLoaded() {
      this.loading = false
    },
  },
}
</script>

<style scoped lang="scss">
.embedded-content-loader {
  margin: auto;
  background-image: url('../assets/embedded-loader.png');
  background-size: cover;
  animation: kbLoadingPulse 1.1s infinite;
  animation-direction: alternate;
  height: 90vh;
}

.embedded-content {
  border: none;
  margin: 0px 10px;
  width: calc(100% - 16px);
  position: absolute;
  height: calc(100% - 64px);
}

@keyframes kbLoadingPulse {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
</style>
