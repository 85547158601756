<template>
  <div class="kb-screen">
    <screen-header :externalLink="url"></screen-header>
    <div class="kb" v-if="url">
      <embedded-content
        :url="embeddedURL"
        i-frame-title="Ovation CXM Knowledge Base Article"
      ></embedded-content>
    </div>
    <div class="kb-load-error" v-if="error">
      <span class="bt-icon bt-icon-error"></span>

      <div class="error-text">We encountered an error loading this article</div>
    </div>
  </div>
</template>

<script>
import ScreenHeader from '@/components/ScreenHeader'
import EmbeddedContent from '@/components/EmbeddedContent'
import { getKB } from '@/api/commApi'

export default {
  name: 'KBScreen',
  props: {
    kb: {
      type: Object,
      required: false,
    },
  },
  inject: ['partnerStore', 'kbStore', 'notificationStore'],
  data: function () {
    return {
      loading: true,
      url: '',
      error: false,
    }
  },
  components: { ScreenHeader, EmbeddedContent },
  computed: {
    embeddedURL() {
      let url
      if (this.url) {
        if (this.url.match(/\?/)) {
          url = this.url + '&viewmode=embedded'
        } else {
          url = this.url + '?viewmode=embedded'
        }
      }

      return url
    },
  },
  mounted() {
    if (this.kb.url) {
      this.url = this.kb.url
      this.loading = false
    } else {
      return getKB(this.kb.id)
        .then(kb => {
          this.url = kb.baseUrl
          this.loading = false
        })
        .catch(error => {
          this.error = true
          this.loading = false
        })
    }
  },
}
</script>

<style scoped lang="scss">
.kb-load-error {
  text-align: center;
  font-size: 20px;
  margin: 48px auto 0px auto;
  width: 80%;
}

.error-text {
  margin-top: 10px;
}

.bt-icon {
  font-size: 33px;
}
</style>
