<template>
  <div class="chat-screen">
    <screen-header :end-chat="showEndChat"></screen-header>
    <transition name="fadeIn">
      <user-identification
        v-show="!memberStore.member.members_id"
      ></user-identification>
    </transition>
    <transition name="fadeIn">
      <chat v-if="memberStore.member.members_id"></chat>
    </transition>
  </div>
</template>

<script>
import ScreenHeader from '@/components/ScreenHeader'
import Chat from '@/components/Chat'
import UserIdentification from '@/components/UserIdentification'
// import CircleLoader from '@/components/CircleLoader';

export default {
  name: 'ChatScreen',
  inject: ['memberStore', 'issueStore', 'chatStore'],
  components: { ScreenHeader, Chat, UserIdentification },
  data: function () {
    return {
      newMessageText: '',
      sendButtonHover: false,
      newFile: '',
      newFileUrl: '',
    }
  },
  methods: {},
  mounted() {},
  computed: {
    showEndChat() {
      if (this.issueStore.issue.id && this.issueStore.issue.active) {
        return true
      } else {
        return false
      }
    },
  },
}
</script>

<style scoped>
.chat-screen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0px;
  right: 0px;
}
</style>
