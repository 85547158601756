<template>
  <div
    class="layout"
    v-if="partnerStore.config.integrationEnabled"
    v-bind:class="{ 'full-screen': configStore.config.fullScreen }"
  >
    <global-notifications></global-notifications>
    <router-view v-slot="{ Component }">
      <transition :name="transitionName">
        <component :is="Component" />
      </transition>
      <!-- toto: add error state -->
    </router-view>
  </div>
</template>

<script>
import { partnerStore } from '@/stores/partnerStore'
import { chatStore } from '@/stores/chatStore'
import { kbStore } from '@/stores/kbStore'
import { journeyStore } from '@/stores/journeyStore'
import { memberStore } from '@/stores/memberStore'
import { issueStore } from '@/stores/issueStore'
import { configStore } from '@/stores/configStore'
import { themeStore } from '@/stores/themeStore'
import { notificationStore } from '@/stores/notificationStore'
import { getConfig, lookupTeam } from '@/api/providersApi'
import { getJourneys } from '@/api/journeyApi'
import { getEmoticons } from '@/api/memberApi'
import { getIssue } from '@/api/issueApi'
import CloseButton from '@/components/CloseButton'
import chatService from '@/services/chatService'
import Cookie from 'js-cookie'
import GlobalNotifications from '@/components/GlobalNotifications'
import { identifyUser } from '@/services/memberService'

export default {
  name: 'App',
  provide: {
    chatStore,
    partnerStore,
    kbStore,
    journeyStore,
    memberStore,
    issueStore,
    configStore,
    themeStore,
    notificationStore,
  },
  components: { CloseButton, GlobalNotifications },
  data: function () {
    return {
      transitionName: 'fromLeft',
      loaded: false,
      error: null,
      partnerStore,
      configStore,
    }
  },
  computed: {},
  watch: {
    $route(to, from) {
      const toDepth = to.path === '/' ? 1 : to.path.split('/').length
      const fromDepth = from.path === '/' ? 1 : from.path.split('/').length
      this.transitionName = toDepth < fromDepth ? 'fromLeft' : 'fromRight'
    },
  },
  methods: {
    async lookupTeam() {
      return lookupTeam().then(teamData => {
        partnerStore.setConfig(teamData.results[0])
      })
    },
    async getConfig() {
      const configData = await getConfig()
      partnerStore.setConfig(configData)
      themeStore.setThemeColor(configData.buttonColor)
    },
    startAppOnPartnerPage() {
      const configData = partnerStore.config
      window.parent.postMessage(
        JSON.stringify({
          type: 'startApp',
          configData: {
            buttonColor: themeStore.theme.primaryColor,
            chatWidgetImage: configData.chatWidgetImage,
            openOnLoad: !!window.sessionStorage.getItem(`${partnerStore.config.integrationId}.app-open`),
            startScreen: window.sessionStorage.getItem(`${partnerStore.config.integrationId}.last-path`),
          },
        }),
        partnerStore.config.parentOrigin,
      )
      if (
        configData.interstitialText &&
        !window.sessionStorage.getItem(`${partnerStore.config.integrationId}.welcome-hidden`)
      ) {
        // dont show welcome if autoreopen or already open
        window.setTimeout(() => {
          if (!configStore.config.appOpen) {
            window.parent.postMessage(
              JSON.stringify({
                type: 'showWelcome',
                welcomeText: configData.interstitialText,
              }),
              partnerStore.config.parentOrigin,
            )
          }
        }, 1000)
      }
    },
    async getJourneys() {
      const journeys = await getJourneys()
      journeyStore.replaceJourneys(journeys)
    },
    async getEmoticons() {
      const emoticons = await getEmoticons()
      chatService.emoticons = emoticons
    },
    async getIssue(issueId) {
      const issue = await getIssue(issueId)
      if (!issue?.status?.match(/Closed/i)) {
        issueStore.setIssue(issue)
      }
    },
    loadMemberData() {
      const memberDataCookie = Cookie.get('memberData')
      let memberDataObj
      let memberData
      if (memberDataCookie) {
        try {
          memberDataObj = JSON.parse(memberDataCookie)
          memberData = memberDataObj[partnerStore.config.integrationId]
          if (memberData) memberStore.setMember(memberData)
        } catch (e) {
          console.log('e', e)
        }
      }

      if (
        !memberStore.member.members_users_id &&
        partnerStore.config.memberUserEmail
      ) {
        identifyUser(partnerStore.config.memberUserEmail)
      }
    },
    loadIssueId() {
      const issueIdCookie = Cookie.get('issueId')
      let issueIdObj
      let issueId
      if (issueIdCookie)
        try {
          issueIdObj = JSON.parse(issueIdCookie)
          if (typeof issueIdObj === 'string') {
            // handling for legacy storage of single issueId as string
            issueId = issueIdObj
          } else {
            issueId = issueIdObj[partnerStore.config.integrationId]
          }
        } catch (e) {
          console.log('e', e)
        }
      return issueId
    },
  },
  mounted() {
    this.loadMemberData()
    const issueId = this.loadIssueId()
    const appDataRequests = [
      this.lookupTeam(),
      this.getConfig(),
      this.getEmoticons(),
      this.getJourneys(),
    ]
    if (issueId) appDataRequests.push(this.getIssue(issueId))

    Promise.all(appDataRequests)
      .then(responses => {
        this.startAppOnPartnerPage()
        this.loaded = true
      })
      .catch(error => {
        this.error = true
        console.log('error: ', error)
      })
  },
}
</script>

<style lang="scss" scoped>
.layout {
  text-align: left;
  padding: 0px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  padding-right: 0px;
  padding-left: 0px;
  right: 0px;
  left: 0px;
  overflow-x: hidden;
}
</style>
