<template>
  <button class="secondary-cta" :disabled="submitting">
    <span v-html="text" v-if="!submitting"></span>
    <transition name="fadeIn">
      <span v-html="submittingText || text" v-if="submitting"></span>
    </transition>
  </button>
</template>

<script>
export default {
  name: 'SecondaryCTA',
  inject: ['themeStore'],
  props: {
    color: {
      type: String,
      required: false,
    },
    submitting: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
    },
    submittingText: {
      type: String,
    },
  },
  data() {
    return {
      secondaryColor: this.themeStore.theme.secondaryColor,
      primaryColor: this.themeStore.theme.primaryColor,
    }
  },
}
</script>

<style scoped lang="scss">
@import '../styles/variables';
.secondary-cta {
  box-sizing: border-box;
  color: #424851;
  border: 1px solid v-bind(primaryColor);
  background: white;
  padding: 12px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  width: 100%;
  transition: all 250ms ease;
}

.secondary-cta:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.secondary-cta:focus-visible {
  outline-color: v-bind(primaryColor);
  outline-style: double;
  outline-width: thick;
  border: none;
}
.secondary-cta:hover {
  background-color: v-bind(secondaryColor);
}
</style>
