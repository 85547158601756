<template>
  <div class="kb-search">
    <form action="" @submit.prevent="submitSearch" class="search-form">
      <div class="input-wrapper">
        <span class="bt-icon bt-icon-search"></span>
        <input
          type="text"
          :placeholder="searchInputPlaceholder"
          class="search-input"
          v-model="kbStore.state.query"
          :disabled="submittingSearch"
        />
        <PrimaryCTA
          class="search-cta"
          :submitting="submittingSearch"
          icon="arrow-right"
        >
        </PrimaryCTA>
      </div>
    </form>

    <div class="results-wrapper" aria-live="polite">
      <transition name="fadeIn">
        <div
          v-if="kbStore.state.results && kbStore.state.results.length == 0"
          class="no-results"
        >
          <div class="results-title">
            No results found for "{{ kbStore.state.lastQuery }}"
          </div>
        </div>
      </transition>
      <transition name="fadeIn">
        <div
          v-if="kbStore.state.results && kbStore.state.results.length > 0"
          class="results"
        >
          <div class="results-title">
            Search results for "{{ kbStore.state.lastQuery }}"
          </div>

          <ul>
            <li
              v-for="(result, index) in limitedResults"
              :key="index"
              class="result-li"
            >
              <button class="result" @click="goToKB(result)">
                <div class="result-inner">
                  <div class="result-title">{{ result.title }}</div>
                  <div class="result-preview">{{ result.preview }}</div>
                </div>
              </button>
            </li>
          </ul>
          <PrimaryCTA
            v-if="resultLimit && kbStore.state.results.length > resultLimit"
            @click="goToKBList()"
            class="primary-cta kb-search-more-results-button"
            id="kb-search-more-results-button"
            text="See More Results"
          ></PrimaryCTA>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { searchKB } from '@/api/kbApi'
import PrimaryCTA from './PrimaryCTA.vue'

export default {
  name: 'KBSearch',
  inject: ['partnerStore', 'kbStore', 'themeStore'],
  components: { PrimaryCTA },
  props: {
    resultLimit: {
      type: Number,
      required: false,
    },
  },
  data: function () {
    return {
      submittingSearch: false,
      searchInputPlaceholder: 'Search Knowledge',
      primaryColor: this.themeStore.theme.primaryColor,
    }
  },
  methods: {
    goToKB(kb) {
      this.$router.push({ name: 'KBScreen', params: { id: kb.id } })
    },
    goToKBList() {
      this.$router.push({ name: 'KBListScreen' })
    },
    submitSearch() {
      if (!this.kbStore.state.query) return
      this.submittingSearch = true
      searchKB(this.kbStore.state.query)
        .then(() => {})
        .catch(() => {
          // todo add error handling?
        })
        .finally(() => {
          this.submittingSearch = false
        })
    },
  },
  computed: {
    limitedResults() {
      if (this.resultLimit) {
        return this.kbStore.state.results.slice(0, this.resultLimit)
      } else {
        return this.kbStore.state.results
      }
    },
  },
  watch: {},
}
</script>

<style scoped lang="scss">
.no-results {
  margin: 0px 8px;
}
.results {
  margin: 0px 8px;
}
.results-title {
  font-weight: 500;
  margin: 18px 0px;
  font-size: 14px;
  color: #596168;
}

.result {
  cursor: pointer;
  border-radius: 6px;
  margin: 0px -8px;
  padding: 0px 8px;
  text-align: left;
  background: transparent;
  width: 104%;

  &:hover {
    background: #f7f8fa;
    transition: 250ms all linear;
  }

  &:focus-visible {
    outline-style: double;
    outline-color: v-bind(primaryColor);
  }
}

.result-li {
  list-style-type: none;
}

.result-inner {
  border-top: 1px solid #e0e0e0;
  padding: 8px 0px;
  line-height: 1.8;
}

.result-title {
  font-weight: 500;
  line-height: 24px;
  color: #424851;
}

.result-preview {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  margin-top: 4px;
  color: #596168;
}

.search-cta {
  width: 46px;
  height: 46px;
  vertical-align: top;
  padding: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  line-height: 24px;
}

.loader-icon {
  margin-top: 2px;
}

.search-form {
  margin: 12px 7px 0px 7px;
}

.search-input {
  vertical-align: top;
  width: calc(100% - 46px);
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.search-input:focus-visible {
  border-color: v-bind(primaryColor);
}

.bt-icon {
  font-weight: bold;
}

.bt-icon-arrow-right {
  margin-top: 6px;
}

.input-wrapper {
  position: relative;
}
.bt-icon-search {
  color: #6b737b;
  position: absolute;
  top: 15px;
  left: 9px;
  font-size: 15px;
}
.search-input::placeholder {
  color: #6b737b;
}

.search-input {
  padding-left: 29px;
}

.kb-search-more-results-button {
  margin-top: 16px;
}
</style>
