var merge = require('lodash/merge')
var CryptoJS = require('crypto-js')
import { reactive, computed, readonly } from 'vue'
import Cookie from 'js-cookie'
import { partnerStore } from './partnerStore'
import { getCookieOptions } from '../utils/helpers'

const issue = reactive({})

function setIssue(issueData) {
  merge(issue, issueData)
  if (issue?.status?.match(/Closed|Resolved|Canceled|Deleted/i)) {
    markInactive()
  } else {
    issue.active = true
  }

  if (issue.xmpp_data) issue.xmppData = parseXMPPData(issue.id, issue.xmpp_data)
  if (issue.id) setCookie(issue.id)
}

function setCookie(issueId) {
  let existingCookie = Cookie.get('issueId')
  let existingIssueIds
  try {
    existingIssueIds = JSON.parse(existingCookie)
  } catch (e) {
    existingIssueIds = {}
  }
  // required to handle legacy cookies from webchat
  if (typeof existingIssueIds === 'string') {
    existingIssueIds = {}
  }
  existingIssueIds[partnerStore.config.integrationId] = issueId

  Cookie.set('issueId', JSON.stringify(existingIssueIds), getCookieOptions())
}

function unsetIssue() {
  let existingCookie = Cookie.get('issueId')
  let existingIssueIds
  try {
    existingIssueIds = JSON.parse(existingCookie)
  } catch (e) {
    existingIssueIds = {}
  }
  // required to handle legacy cookies from webchat
  if (typeof existingIssueIds === 'string') {
    existingIssueIds = {}
  } else {
    delete existingIssueIds[partnerStore.config.integrationId]
  }
  Cookie.set('issueId', JSON.stringify(existingIssueIds), getCookieOptions())
  Object.keys(issue).forEach(key => {
    issue[key] = null
  })
}

function markInactive() {
  issue.active = false
}

function addFeedback(feedback) {
  issue.feedback = feedback
}

function parseXMPPData(issueId, xmppData) {
  xmppData = JSON.parse(xmppData)

  var digest = CryptoJS.SHA256(issueId + xmppData.nonce).toString(
    CryptoJS.enc.Hex,
  )
  var key = digest.substr(0, 32)
  var iv = digest.substr(32, 16)
  var decryptResult = CryptoJS.AES.decrypt(
    xmppData.ct,
    CryptoJS.enc.Utf8.parse(key),
    { iv: CryptoJS.enc.Utf8.parse(iv) },
  )

  return JSON.parse(decryptResult.toString(CryptoJS.enc.Utf8))
}

export const issueStore = readonly({
  issue,
  setIssue,
  unsetIssue,
  markInactive,
  addFeedback,
})
